import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import * as strings from "../assets/strings";
import { CanDeactivateGuardService } from "./core/can-deactivate-guard.service";
import { LoginGuardService } from "./core/login-guard.service";
import { RolesGuardService } from "./core/roles-guard.service";
import { SelectivePreloadingStrategyService } from "./core/selective-preloading-strategy.service";
import { AgropolosHttpStatusComponent } from "./shell/components/agropolos-http-status/agropolos-http-status.component";
import { ShellComponent } from "./shell/shell.component";

export const routes: Routes = [
  {
    path: "",
    component: ShellComponent,
    children: [
      { path: "", redirectTo: `login`, pathMatch: "full" },
      {
        path: "login",
        loadChildren: () =>
          import("./shell/components/login/login.module").then(m => m.LoginModule),
        data: { preload: true }
      },
    ]
  },
  {
    path: "",
    component: ShellComponent,
    children: [
      {
        path: "visualizaassinatura",
        loadChildren: () =>
          import("./shell/components/viaualizar-assinatura/viaualizar-assinatura.module").then(m => m.VisualizaAssinaturaModule),
        data: { preload: true }
      },
    ]
  },
  {
    path: "",
    component: ShellComponent,
    children: [
      {
        path: "editalmulherlocalizacao",
        loadChildren: () =>
          import("./shell/components/edital-mulher-localizacao/edital-mulher-localizacao.module").then(m => m.EditalMulherLocalizacaoModule),
        data: { preload: true }
      },
    ]
  },
  {
    path: "editalmulheresrurais",
    component: ShellComponent,
    children: [
      {
        path: "editalmulheresrurais",
        loadChildren: () =>
          import("./shell/components/abertura-edital/abertura-edital.module").then(m => m.AberturaEditalModule),
        data: { preload: true }
      },
    ]
  },
  {
    path: "listapresencaevento",
    component: ShellComponent,
    children: [
      {
        path: "listapresencaevento", 
        loadChildren: () =>
           import("./shell/components/lista-presenca-evento/lista-presenca-evento.module").then(m => m.ListaPresencaEventoModule),
        data: { preload: true }
      },
    ]
  },
  {
    path: "pesquisasatisfacaofeira",
    component: ShellComponent,
    loadChildren: () =>
      import("./shell/components/pesquisa-satisfacao-feira/pesquisa-satisfacao-feira.module").then(m => m.PesquisaSatisfacaoFeiraModule),
    data: { preload: true }
  },
  {
    path: "editalmulheresruraiscadastro",
    component: ShellComponent,
    loadChildren: () =>
    import("./shell/components/edital-mulher-rural/edital-mulher-rural.module").then(m => m.EditalMulherRuralModule),
  },
  {
    path: "licitaben",
    component: ShellComponent,
    loadChildren: () =>
    import("./shell/components/licitaben-pre-cadastro/licitaben-pre-cadastro.module").then(m => m.LicitabenPreCadastroModule),
  },
  {
    path: "fazendocontas",
    component: ShellComponent,
    loadChildren: () =>
    import("./shell/components/fazendo-contas-pre-cadastro/fazendo-contas-pre-cadastro.module").then(m => m.FazendoContasPreCadastroModule),
  },
  {
    path: "principal",
    component: ShellComponent,
    loadChildren: () =>
      import("../app/business-modules/principal/principal.module").then(m => m.PrincipalModule)
  },
  {
    path: "dashboard",
    component: ShellComponent,
    loadChildren: () =>
      import("../app/business-modules/dashboard/dashboard.module").then(m => m.DashboardModule)
  },
  {
    path: "googleapi",
    component: ShellComponent,
    loadChildren: () =>
      import("../app/business-modules/google-maps-api/google-maps-api.module").then(m => m.GoogleApiModule)
  },
  {
    path: "acesso-negado",
    component: AgropolosHttpStatusComponent,
    data: {
      system: strings.sigla,
      code: 401,
      title: "Acesso negado!",
      message: "Desculpe, você não tem permissão para acessar esta página."
    }
  },
  {
    path: "zeradamodelo",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/zerada-modelo/zerada-modelo.module").then(
        m => m.ZeradaModeloModule
      )
  },
  {
    path: "avisogeral",
    component: ShellComponent,
    children: [
      {
        path: "avisogeral",
        loadChildren: () =>
          import("./shell/components/aviso-geral/aviso-geral.module").then(m => m.AvisoGeralModule),
        data: { preload: true }
      },
    ]
  },
  // {
  //   path: "**",
  //   component: AgropolosHttpStatusComponent,
  //   data: {
  //     system: strings.sigla,
  //     code: 404,
  //     title: "Página não encontrada!",
  //     message: "Desculpe, a página que você tentou acessar não foi encontrada."
  //   }
  // },
  {
    path: "pessoa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/pessoa/pessoa/pessoa.module").then(
        m => m.PessoaModule
      )
  },
  {
    path: "localidade",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/localidade/localidade.module").then(
        m => m.LocalidadeModule
      )
  },
  {
    path: "itemCusteio",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/itemCusteio/itemCusteio.module").then(
        m => m.ItemCusteioModule
      )
  },
  {
    path: "cargo",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cargo/cargo.module").then(
        m => m.CargoModule
      )
  },
  {
    path: "planoprojeto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/plano-projeto/plano-projeto.module").then(
        m => m.PlanoProjetoModule
      )
  },
  {
    path: "organogramacontratante",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/organograma-contratante/organograma-contratante.module").then(
        m => m.OrganogramaContratanteModule
      )

  },
  {
    path: "custeiopessoal",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/custeio-pessoal/custeio-pessoal.module").then(
        m => m.CusteioPessoalModule
      )
  },
  {
    path: "cargogestaopessoal",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cargo-gestao-pessoa/cargo-gestao-pessoa.module").then(
        m => m.CargoGestaoPessoaModule
      )
  },
  {
    path: "beneficio",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/beneficio/beneficio.module").then(
        m => m.BeneficioModule
      )
  },
  {
    path: "encargos",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/encargos/encargos.module").then(
        m => m.EncargosModule
      )
  },
  // {
  //   path: "dissidio",
  //   component: ShellComponent,
  //   canActivateChild: [LoginGuardService],
  //   loadChildren: () =>
  //     import("./business-modules/cadastro/dissidio/dissidio.module").then(
  //       m => m.DissidioModule
  //     )
  // },
  {
    path: "retencao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/retencao/retencao.module").then(
        m => m.RetencaoModule
      )
  },
  {
    path: "provisao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/provisao/provisao.module").then(
        m => m.ProvisaoModule
      )
  },
  {
    path: "elementodespesa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/elemento-despesa/elemento-despesa.module").then(
        m => m.ElementoDespesaModule
      )
  },
  {
    path: "pessoajuridica",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/pessoa-juridica/pessoa-juridica.module").then(
        m => m.PessoaJuridicaModule
      )
  },
  {
    path: "termoreferencia",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/termo-referencia/termo-referencia.module").then(
        m => m.TermoReferenciaModule
      )
  },
  {
    path: "funcionario",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/funcionario/funcionario.module").then(
        m => m.FuncionarioModule
      )
  },
  {
    path: "membros",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/membros/membros.module").then(
        m => m.MembroModule
      )
  },
  {
    path: "indicador",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/indicador/indicador.module").then(
        m => m.IndicadorModule
      )
  },
  {
    path: "dominio/:id",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/dominio/dominio/dominio.module").then(m => m.DominioModule),
  },
  {
    path: "predominio/:id",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import(
        "./business-modules/cadastro/dominio/dominio/predominio/pre-dominio.module"
      ).then(m => m.PreDominioModule)
  },
  {
    path: "planotrabalho",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/plano-trabalho/plano-trabalho.module").then(
        m => m.PlanoTrabalhoModule
      )
  },
  {
    path: "planocontas",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/plano-contas/plano-contas.module").then(
        m => m.PlanoContasModule
      )
  },
  {
    path: "orcamentoproduto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/orcamento-produto/orcamento-produto.module").then(
        m => m.OrcamentoProdutoModule
      )
  },
  {
    path: "planocontasmatriz",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import(
        "./business-modules/plano-contas-matriz/plano-contas-matriz.module"
      ).then(m => m.PlanoContasMatrizModule)
  },
  {
    path: "questao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import(
        "./business-modules/cadastro/questionario/questao/questao.module"
      ).then(m => m.QuestaoModule)
  },
  // {
  //   path: "whatsnews",
  //   component: ShellComponent,
  //   canActivateChild: [LoginGuardService],
  //   loadChildren: () =>
  //     import("./business-modules/cadastro/whatsNews/whatsnews.module").then(
  //       m => m.WhatsNewsModule
  //     )
  // },
  {
    path: "pessoafisica",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/pessoa-fisica/pessoa-fisica.module").then(
        m => m.PessoaFisicaModule
      )
  },
  // {
  //   path: "despesasadministrativaos",
  //   component: ShellComponent,
  //   canActivateChild: [LoginGuardService],
  //   loadChildren: () =>
  //     import("./business-modules/despesas-administrativas-os/despesas-administrativas-os.module").then(
  //       m => m.DespesasAdministrativasOSModule
  //     )
  // },
  {
    path: "relatorio",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/relatorio/relatorio.module").then(
        m => m.RelatorioModule
      )
  },
  {
    path: "contratoreceita",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/contrato-receita/contrato-receita.module").then(
        m => m.ContratoReceitaModule
      )
  }, {
    path: "mrtcontratoreceita",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mrt-contrato-receita/mrt-contrato-receita.module").then(
        m => m.MrtContratoReceitaModule
      )
  },
  {
    path: "mrtatividade",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mrt-atividade/mrt-atividade.module").then(
        m => m.MrtAtividadeModule
      )
  },
  {
    path: "mrtmeta",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mrt-meta/mrt-meta.module").then(
        m => m.MrtMetaModule
      )
  },
  {
    path: "cargogestao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cargo-gestao/cargo-gestao.module").then(
        m => m.CargoGestaoModule
      )
  },
  {
    path: "documento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/documento/documento.module").then(
        m => m.DocumentoModule
      )
  }, {
    path: "controledocumento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/controle-documento/controle-documento.module").then(
        m => m.ControleDocumentoModule
      )
  },
  {
    path: "processotrabalho",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/processo-trabalho/processo-trabalho.module").then(
        m => m.ProcessoTrabalhoModule
      )
  },
  {
    path: "execucaoproduto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/execucao-produto/execucao-produto.module").then(
        m => m.ExecucaoProdutoModule
      )
  },
  {
    path: "produto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/produto/produto.module").then(
        m => m.ProdutoModule
      )
  },
  {
    path: "fortesfinanceiro",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/fortes-financeiro/fortes-financeiro.module").then(
        m => m.FortesFinanceiroModule
      )
  },
  {
    path: "fortesfinanceirorelacoesbaixas",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/fortes-financeiro-relacoes-baixas/fortes-financeiro-relacoes-baixas.module").then(
        m => m.FortesFinanceiroRelacoesBaixasModule
      )
  },
  {
    path: "itemdespesa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/item-despesa/item-despesa.module").then(
        m => m.ItemDespesaModule
      )
  },
  {
    path: "configuracaodiaria",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/configuracao-diaria/configuracao-diaria.module").then(
        m => m.ConfiguracaoDiariaModule
      )
  },
  {
    path: "contratodespesa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/contrato-despesa/contrato-despesa.module").then(
        m => m.ContratoDespesaModule
      )
  },
  {
    path: "solicitacaodespesa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/solicitacao-despesa/solicitacao-despesa.module").then(
        m => m.SolicitacaoDespesaModule
      )
  },
  {
    path: "centrocusto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/centro-custo/centro-custo.module").then(
        m => m.CentroCustoModule
      )
  },
  {
    path: "organograma",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/organograma/organograma.module").then(
        m => m.OrganogramaModule
      )
  },
  {
    path: "solicitacaodespesa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/solicitacao-despesa/solicitacao-despesa.module").then(
        m => m.SolicitacaoDespesaModule
      )
  },
  {
    path: "protocolo",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/protocolo/protocolo.module").then(
        m => m.ProtocoloModule
      )
  },
  {
    path: "planejamentodesembolso",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/planejamento-desembolso/planejamento-desembolso.module").then(
        m => m.PlanejamentoDesembolsoModule
      )
  },
  {
    path: "desembolsoplanejamento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/desembolso-planejamento/desembolso-planejamento.module").then(
        m => m.DesembolsoPlanejamentoModule
      )
  },
  {
    path: "solicitacaopagamento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/solicitacao-pagamento/solicitacao-pagamento.module").then(
        m => m.SolicitacaoPagamentoModule
      )
  },
  {
    path: "descontopagamento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/desconto-pagamento/desconto-pagamento.module").then(
        m => m.DescontoPagamentoModule
      )
  },
  {
    path: "retencaopagamento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/retencao-pagamento/retencao-pagamento.module").then(
        m => m.RetencaoPagamentoModule
      )
  },
  {
    path: "entregaproduto",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/entrega-produto/entrega-produto.module").then(
        m => m.EntregaProdutoModule
      )
  },
  {
    path: "elementodespesaitemcontabil",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/cadastro/elemento-despesa-item-contabil/elemento-despesa-item-contabil.module").then(
        m => m.ElementoDespesaItemContabilModule
      )
  },
  {
    path: "execucaodespesageral",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/execucao-despesa-geral/execucao-despesa-geral.module").then(
        m => m.ExecucaoDespesaGeralModule
      )
  },
  {
    path: "acompanhamentoexecucaodespesa",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/acompanhamento-execucao-despesa/acompanhamento-execucao-despesa.module").then(
        m => m.AcompanhamentoExecucaoDespesaModule
      )
  },
  {
    path: "doacaoitem",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-item/doacao-item.module").then(
        m => m.DoacaoItemModule
      )
  },
  {
    path: "doacaocaptacao",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-captacao/doacao-captacao.module").then(
        m => m.DoacaoCaptacaoModule
      )
  },
  {
    path: "doacaosaida",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-saida/doacao-saida.module").then(
        m => m.DoacaoSaidaModule
      )
  },
  {
    path: "doacaoentradaestoque",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-entrada-estoque/doacao-entrada-estoque.module").then(
        m => m.DoacaoEntradaEstoqueModule
      )
  },
  {
    path: "doacaoempacotamento",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-empacotamento/doacao-empacotamento.module").then(
        m => m.DoacaoEmpacotamentoModule
      )
  },
  {
    path: "doacaosaidadescarte",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-saida-descarte/doacao-saida-descarte.module").then(
        m => m.DoacaoSaidaDescarteModule
      )
  },
  {
    path: "doacaoconfiguracaomix",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-configuracao-mix/doacao-configuracao-mix.module").then(
        m => m.DoacaoConfiguracaoMixModule
      )
  },
  {
    path: "doacaoprodutofinal",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/doacao-produto-final/doacao-produto-final.module").then(
        m => m.DoacaoProdutoFinalModule
      )
  },
  {
    path: "criacaousuario",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/criacao-usuario/criacao-usuario.module").then(
        m => m.CriacaoUsuarioModule
      )
  },
  {
    path: "aniversariante",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/aniversariante/aniversariante.module").then(
        m => m.AniversarianteModule
      )
  },
  {
    path: "administracaoprotocolo",
    component: ShellComponent,
    // canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/administracao-protocolo/administracao-protocolo.module").then(
        m => m.AdministracaoProtocoloModule
      )
  },
  {
    path: "avaliacaorisco",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/avaliacao-risco/avaliacao-risco.module").then(
        m => m.AvaliacaoRiscoModule
      )
  },{
    path: "planoaquisicao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/plano-aquisicao/plano-aquisicao.module").then(
        m => m.PlanoAquisicaoModule
      )
  },
  {
    path: "licitacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/licitacao/licitacao.module").then(
        m => m.LicitacaoModule
      )
  },
  {
    path: "planogerenciamentocontrato",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/plano-gerenciamento-contrato/plano-gerenciamento-contrato.module").then(
        m => m.PlanoGerenciamentoContratoModule
      )
  },
  {
    path: "tipotramitacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/tipo-tramitacao/tipo-tramitacao.module").then(
        m => m.TipoTramitacaoModule
      )
  },
  {
    path: "mianalisecapacitacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mi-analise-capacitacao/mi-analise-capacitacao.module").then(
        m => m.MiAnaliseCapacitacaoModule
      )
  },
  {
    path: "reuniaonucleogestor",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/reuniao-nucleo-gestor/reuniao-nucleo-gestor.module").then(
        m => m.ReuniaoNucleoGestorModule
      )
  },
  {
    path: "analisedocumentoprojeto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/analise-documento-projeto/analise-documento-projeto.module").then(
        m => m.AnaliseDocumentoProjetoModule
      )
  },
  {
    path: "graficogerencial",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/graficos/graficos.module").then(
        m => m.GraficosModule
      )
  },
  {
    path: "analisediagnostico",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/analise-diagnostico/analise-diagnostico.module").then(
        m => m.AnaliseDiagnosticoModule
      )
  },
  {
    path: "gerenciamentoplanoacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/gerenciamento-plano-acao/gerenciamento-plano-acao.module").then(
        m => m.GerenciamentoPlanoAcaoModule
      )
  },
  {
    path: "miproponentejovem",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mi-proponente-jovem/mi-proponente-jovem.module").then(
        m => m.MiProponenteJovemModule
      )
  },
  {
    path: "miproponenteorganizacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mi-proponente-organizacao/mi-proponente-organizacao.module").then(
        m => m.MiProponenteOrganizacaoModule
      )
  },
  {
    path: "mimulherrural",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/mi-mulher-rural/mi-mulher-rural.module").then(
        m => m.MiMulherRuralModule
      )
  },
  {
    path: "atendimentoaplicativo",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/atendimento-aplicativo/atendimento-aplicativo.module").then(
        m => m.AtendimentoAplicativoModule
      )
  },
  {
    path: "suiteugppsj",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/suite-ugp-psj/suite-ugp-psj.module").then(
        m => m.SuiteUgpPsjModule
      )
  },
  {
    path: "agendamentoevento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/agendamento-evento/agendamento-evento.module").then(
        m => m.AgendamentoEventoModule
      )
  },
  {
    path: "controleportaria",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/controle-portaria/controle-portaria.module").then(
        m => m.ControlePortariaModule
      )
  },
  {
    path: "veiculoinformacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/veiculo-informacao/veiculo-informacao.module").then(
        m => m.VeiculoInformacaoModule
      )
  },
  {
    path: "veiculosolicitacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/veiculo-solicitacao/veiculo-solicitacao.module").then(
        m => m.VeiculoSolicitacaoModule
      )
  },
  {
    path: "veiculoresumo",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/veiculo-resumo/veiculo-resumo.module").then(
        m => m.VeiculoResumoModule
      )
  },
  {
    path: "controlenumeracaodocumento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/controle-numeracao-documento/controle-numeracao-documento.module").then(
        m => m.ControleNumeracaoDocumentoModule
      )
  },
  {
    path: "resumoetapa",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/resumo-etapa/resumo-etapa.module").then(
        m => m.ResumoEtapaModule
      )
  },  
  {
    path: "licitabenlicitacaoproposta",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/licitaben/licitaben-proposta/licitaben-proposta.module").then(
        m => m.LicitabenLicitacaoPropostaModule
      )
  },  
  {
    path: "licitabenlicitacao",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/licitaben/licitaben-licitacao/licitaben-licitacao.module").then(
        m => m.LicitabenLicitacaoModule
      )
  },
  {
    path: "licitabenfornecedor",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/licitaben/licitaben-fornecedor/licitaben-fornecedor.module").then(
        m => m.LicitabenFornecedorModule
      )
  },
  {
    path: "licitabentermofomento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/licitaben/licitaben-termo-fomento/licitaben-termo-fomento.module").then(
        m => m.LicitabenTermoFomentoModule
      )
  }, 
  {
    path: "fazendocontasbeneficiario",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/fazendo-contas/fazendo-contas-beneficiario/fazendo-contas-beneficiario.module").then(
        m => m.FazendoContasBeneficiarioModule
      )
  },
  {
    path: "fazendocontasproduto",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/fazendo-contas/fazendo-contas-produto/fazendo-contas-produto.module").then(
        m => m.FazendoContasProdutoModule
      )
  },
  {
    path: "fazendocontasvenda",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/fazendo-contas/fazendo-contas-venda/fazendo-contas.module").then(
        m => m.FazendoContasModule
      )
  },
  {
    path: "questionario",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/questionario/questionario.module").then(
        m => m.QuestionarioModule
      )
  },
  {
    path: "capacitacaoevento",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/capacitacao-evento/capacitacao-evento.module").then(
        m => m.CapacitacaoEventoModule
      )
  },
  {
    path: "noticiapsj",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/noticia-psj/noticia-psj.module").then(
        m => m.NoticiaPsjModule
      )
  },
  {
    path: "chamadoatividade",
    component: ShellComponent,
    canActivateChild: [LoginGuardService],
    loadChildren: () =>
      import("./business-modules/chamado-atividade/chamado-atividade.module").then(
        m => m.ChamadoAtividadeModule
      )
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      preloadingStrategy: SelectivePreloadingStrategyService,
      onSameUrlNavigation: "reload"
    })
  ],
  exports: [RouterModule],
  providers: [
    SelectivePreloadingStrategyService,
    LoginGuardService,
    CanDeactivateGuardService,
    RolesGuardService
  ]

})
export class AppRoutingModule {

}
