import * as strings from '../../../../assets/strings';
import { AvisoGeralService } from './aviso-geral.service';
import { NavegacaoService } from '../../../core/navegacao.service';
import { CredenciaisService } from '../../../core/credenciais.service';
import { Component, ElementRef, EventEmitter, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { LocalStorageService } from '../../../core/local-storage.service';
import { MatDialog } from "@angular/material/dialog";
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { catchError, first, tap } from 'rxjs/operators';
import { HttpBaseService } from '../../../core/http-base.service';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { Subscription, throwError, timer } from 'rxjs';
import { startWith, map, take } from "rxjs/operators";

@Component({
  templateUrl: './aviso-geral.component.html',
  styleUrls: ['./aviso-geral.component.scss']
})
export class AvisoGeralComponent  implements OnInit  {

  public hoje: any = new Date(moment(Date.now()).format('YYYY-MM-DD'));  
  theme:any;
  url: string;
  src1: string = 'https://app.powerbi.com/view?r=eyJrIjoiOGMyNjRkYmMtNTcyNC00NDk0LTg1ZDctZDRlZWQ2YzdmMTk2IiwidCI6IjgxNDg2ZjYwLWFhODYtNGE2NC1hMmZjLWVhYTlmZjdhY2UzYSJ9';
  src2: string = 'https://app.powerbi.com/view?r=eyJrIjoiNGVjOTYzOTItODc1My00MTlkLThjODAtOGUxYWVkZDUxNzU1IiwidCI6IjgxNDg2ZjYwLWFhODYtNGE2NC1hMmZjLWVhYTlmZjdhY2UzYSJ9';
  src3: string = 'https://app.powerbi.com/view?r=eyJrIjoiYmExZGU0OTYtZTUyOC00YjQzLWExZDItMDI5ZjU0MzU4NjA3IiwidCI6IjgxNDg2ZjYwLWFhODYtNGE2NC1hMmZjLWVhYTlmZjdhY2UzYSJ9';

  showDashboard:boolean=false;
  showAniversariante:boolean=false;
  showNoticia:boolean=false;

  nomePessoa:string;
  fotoPessoa:any;

  tituloNoticia:string;
  noticia:string;
  fotoNoticia:string;

  numeroOrdem: number;
  @Output() numberGenerated = new EventEmitter<number>();
  @ViewChild('iframe',{static:true}) iframe: ElementRef;

  //parametros pra timer de verificar agenda
  countDown: Subscription;
  counter;// = 60;//60 segundos = 1 minuto
  tick = 1000;

  sequencia:number=0;

  countadorOrdem:number=10;

  constructor(private loginService: AvisoGeralService,
    public credenciaisService: CredenciaisService,
    private localStorageService: LocalStorageService,
    private navegacaoService: NavegacaoService,
    private http: HttpBaseService,
    public dialog: MatDialog,
    private router: Router,
    private _sanitizer: DomSanitizer,
    private httpClient: HttpClient,
  ) {
  }

  ngAfterViewInit() {    
    this.rotinaAtualizar(); 
  }

  cardDashboard(): void {
    //this.countadorOrdem = 20; 
    var x = document.getElementById("diviframe");
    x.style.display = "block";

    // if (x.style.display === "none") {
    //   x.style.display = "block";
    // } 
    // else {
    //   x.style.display = "none";
    // }

    this.showDashboard = true;
    this.showAniversariante = this.showNoticia = !this.showDashboard;

    this.numeroOrdem = Math.floor(3 * Math.random());//this.numberGenerated.emit(this.numeroOrdem);
    if(this.numeroOrdem==1){this.iframe.nativeElement.src = this.src1;}
    if(this.numeroOrdem==2){this.iframe.nativeElement.src = this.src2;}
    if(this.numeroOrdem==3){this.iframe.nativeElement.src = this.src3;}
    //console.log("generateNumber", this.numeroOrdem)
  }

  cardAniversariante()
  {
    //this.countadorOrdem = 5; 
    var x = document.getElementById("diviframe");
    x.style.display = "none";
    this.nomePessoa = "Maria de Deus";

    this.showAniversariante = true;
    this.showDashboard = this.showNoticia = !this.showAniversariante;
  }

  cardNoticia()
  {
    //this.countadorOrdem = 10; 
    var x = document.getElementById("diviframe");
    x.style.display = "none";

    // this.http.get("/PessoaFisica/EnviarEmailAcessoUsuario?codCpf=" + CodCpf, false).subscribe((enviou) => {

    // });

    this.tituloNoticia = "Tecnologia e inovação: Projeto São José e Nutec unem esforços para fortalecer agricultura familiar";
    this.fotoNoticia = "https://www.sda.ce.gov.br/wp-content/uploads/sites/60/2025/02/WhatsApp-Image-2025-02-10-at-14.03.00-2048x1528.jpeg";
    this.noticia = "Na manhã desta segunda-feira (10), a Secretaria do Desenvolvimento Agrário (SDA), promoveu uma reunião entre a equipe técnica do Projeto São José e o Núcleo de Tecnologia e Qualidade Industrial do Ceará (Nutec). O encontro teve como foco o fortalecimento de parcerias estratégicas voltadas para a inovação e a modernização da agricultura familiar no estado. ";
    
    this.showNoticia = true;
    this.showDashboard = this.showAniversariante = !this.showNoticia;
  }

  gerarOrdem(): void {
    // this.numeroOrdem = Math.floor(3 * Math.random());//this.numberGenerated.emit(this.numeroOrdem);
    // this.numeroOrdem==0 && 
    // this.numeroOrdem==1 && 
    // this.numeroOrdem==2 && 
    if(this.sequencia==0){this.cardDashboard();}
    if(this.sequencia==1){this.cardAniversariante();}
    if(this.sequencia==2){this.cardNoticia();}
    
    this.sequencia++;
    if(this.sequencia==3)
      {this.sequencia=0;}
    
    //console.log("numeroOrdem", this.numeroOrdem)
    //console.log("this.sequencia", this.sequencia)
  }

  ngOnInit() { 
    //this.countadorOrdem = 1;
    this.iframe.nativeElement.src = this.src1;
   }
  

  rotinaAtualizar() {
    this.counter = this.countadorOrdem; 
    this.countDown = timer(0, this.tick).subscribe((count) => {
      --this.counter;

      if (this.counter == 0) {
        //console.log('timer completed', count, this.counter);
        this.gerarOrdem();
        
        if (this.countDown) {
          this.pararRotina();
          this.rotinaAtualizar();
        }
      }
     
      //console.log('this.countDown', this.countDown);
      //console.log('this.counter', this.counter);
    });
  }

  pararRotina(){
    this.countDown.unsubscribe();
    this.countDown = null;
  }
}

