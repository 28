import * as strings from '../../../../assets/strings';
import { NavegacaoService } from '../../../core/navegacao.service';
import { CredenciaisService } from '../../../core/credenciais.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '../../../core/local-storage.service';
import { MatDialog } from "@angular/material/dialog";
import { EditalMulherLocalizacaoService } from './edital-mulher-localizacao.service';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MiMulherRural } from '../../../models/integra/mi-mulher-rural';
import { HttpBaseService } from '../../../core/http-base.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { Dominio } from '../../../models/integra/Dominio';
import { map, startWith } from 'rxjs/operators';

@Component({
  templateUrl: './edital-mulher-localizacao.component.html',
  styleUrls: ['./edital-mulher-localizacao.component.scss']
})
export class EditalMulherLocalizacaoComponent implements OnInit {

  theme: string;
  public id: number;
  public tipo: string;
  public showResidencial: boolean = false;
  public showLogin: boolean = false;
  public formGroup: FormGroup;
  public entity: MiMulherRural;
  public usuarioLogado: any;
  public cpfUsuarioLogado: string;
  public miMulherRuralId: number;

  public distritoList: Dominio[] = [];

  public municipioList = [];
  selectMunicipioControl = new FormControl();
  filteredMunicipioOptions: Observable<Dominio[]>;
  public municipioId: number;
  longitudeResdencial: number;
  latitudeResidencial: number;

  constructor(private editalMulherLocalizacaoService: EditalMulherLocalizacaoService,
    public credenciaisService: CredenciaisService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private navegacaoService: NavegacaoService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private http: HttpBaseService,
    private httpClient: HttpClient,
    private _Service: EditalMulherLocalizacaoService,
    private _sanitizer: DomSanitizer,
    ) 
  {
    //this.usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
    //this.cpfUsuarioLogado = this.usuarioLogado.cpf;
      this.id = this.route.snapshot.params.id;
      //this.tipo = this.route.snapshot.params.tipo;

      // this.theme = (this.localStorageService.getObject('theme-app') === 0 || this.localStorageService.getObject('theme-app') !== undefined
      // ? 'agropolos-material-theme' : 'agropolos-material-dark-theme');

      this.initEntity();
      this.createFormValidators();
  }

  private initEntity(): MiMulherRural {
    return new MiMulherRural(null,null,null,null, null,null,null,null, null,null,null,null,null,null,null, null,null,null,null,null, null,null, null, null, null, null,null,null, null, null, null, null,null,null,null,null,null,null,null,null, null, null, null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, null,  null,null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
  }

  private createFormValidators(): void {
    if (this.entity === undefined) this.entity = new MiMulherRural(0,null,null, null,null,null, null,null, null,null, null,null, null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null, null, null, null, null, null,  null,null, null, null, null, null, null, null, null, null, null, null, null, null, null , null, null, null, null, null, null, null, null, null, null, null, null, null, null, null);
    this.formGroup = this.formBuilder.group({
      id: new FormControl({ value: this.entity.id, disabled: true }),
      cepEnderecoResidencial: new FormControl({ value: this.entity.cepEnderecoResidencial, disabled: false }),
      numEnderecoResidencial: new FormControl({ value: this.entity.numEnderecoResidencial, disabled: false }),
      dscEnderecoResidencial: new FormControl({ value: this.entity.dscEnderecoResidencial, disabled: false }),
      dscBairroEnderecoResidencial: new FormControl({ value: this.entity.dscBairroEnderecoResidencial, disabled: false }),
      distritoId: new FormControl({ value: this.entity.distritoId, disabled: false }),
      municipioId: new FormControl({ value: this.entity.municipioId, disabled: false }),
      territorio: new FormControl({ value: this.entity.territorio, disabled: true }),
      comunidade: new FormControl({ value: this.entity.comunidade, disabled: false }),
      numLatitudeResidencial: new FormControl({ value: this.entity.numLatitudeResidencial, disabled: true }),
      numLongitudeResidencial: new FormControl({ value: this.entity.numLongitudeResidencial, disabled: true }),
      flgMeioRural: new FormControl({ value: this.entity.flgMeioRural, disabled: false }),
    });
  }

 

  ngOnInit() {
    //console.log(" this.cpfUsuarioLogado", this.cpfUsuarioLogado)
    // //this.http.get('/mimulherrural/localizaMulher?cpf=' + this.cpfUsuarioLogado, false).subscribe((info: any) => {
        //this.http.get('/mimulherrural/localizaMulherHash/' + this.usuarioLogado.hash, false).subscribe((info: any) => {
    ////console.log(" ngOnInit", info)
    //   if(info != null)
    //   {
    //     this.entity = info;
    //     //console.log("ProjetoMulher---this.entity", this.entity)
    //     //this.id = this.entity.id;
    //     this.miMulherRuralId = this.id;
  
    //     this.municipioId = this.entity.municipioId;
    //     this.selectMunicipioControl.setValue(this.entity.municipio);
    //     this.carregaDistrito(this.municipioId);
    //   }
      

    //   //this.createFormValidators();
    //   //this.router.navigate([`mimulherrural/cadastro`, info.id]);
    // });

    //console.log("this.route.snapshot.params",this.route.snapshot.params)
    //console.log("this.id",this.id)
    //console.log("this.tipo",this.tipo)

    this.carregaMunicipio();
     //if(this.tipo=="R")
     //{
      this.showLogin = true;
      this.showResidencial = false;

     //}

     this.filteredMunicipioOptions = this.selectMunicipioControl.valueChanges
      .pipe(
        startWith(''),
        map(value1 => this._filterMunicipio(value1))
      );
     

    //this.getAssinatura(this.id)
  }


  localizacao() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitudeResidencial = position.coords.latitude;
        this.longitudeResdencial = position.coords.longitude;
      });
    } else {
      alert("Erro ao Obter Localização.");
    }
  }   

  private _filterMunicipio(value: string): Dominio[] {
    let valor = value ? value.toString().toLowerCase() : null;
    this.municipioId = valor != null ? this.municipioId : null;
    this.formGroup.controls["municipioId"].setValue(valor != null ? this.municipioId : null);

    return this.municipioList.filter((option) => option.nome.toLowerCase().includes(valor));// || option.organograma.dscSigla.toLowerCase().includes(value));
  }

  displayFnMunicipio(object?: any): string | undefined {
    return object ? object.nome : undefined;
  }

  municipioSelected(evt: any) {
    if (evt.source.selected) {
      this.municipioId = evt.source.value.id;
      this.carregaDistrito(this.municipioId);
    }
  }

  public carregaMunicipio(): void {
    this.http.get('/Dominio/BuscarMunicipiosPorDominioPai?id=28', false).subscribe((tcList: any[]) => {
      this.municipioList = tcList
    });
  }

  public carregaDistrito(idMunicipio: number): void {
    this.http.get('/Dominio/BuscarDistritosPorDominioPai?id=' + idMunicipio, false).subscribe((tcList: any[]) => {
      this.distritoList = tcList
      
      if (idMunicipio > 0) {
        this.http.get('/VeiculoPlanoViagem/BuscarMunicipioRegiao?municipioId=' + idMunicipio, false).subscribe(dado => {
          // console.log("carregaDistrito", dado)
          this.formGroup.controls["territorio"].setValue(dado.regiao);
        });
      }
    });
  }

  login()
  {
    this.showLogin = false;
    this.showResidencial = true;
  }

  goBack()
  {
    this.showLogin = true;
    this.showResidencial = false;
  }

}
