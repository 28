import { RouterModule } from '@angular/router';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { ShellComponent } from './shell.component';
import { NgModule } from '@angular/core';
import { LoginModule } from './components/login/login.module';
import { AgropolosMaterialModule } from './agropolos-material.module';
import { ChangePasswordModule } from './components/change-password/change-password.module';
import { ForgotPasswordModule } from './components/forgot-password/forgot-password.module';
import { VisualizaAssinaturaModule } from './components/viaualizar-assinatura/viaualizar-assinatura.module';
import { AberturaEditalModule } from './components/abertura-edital/abertura-edital.module';
import { EditalMulherLocalizacaoModule } from './components/edital-mulher-localizacao/edital-mulher-localizacao.module';
import { VideoManualModalModule } from './components/video-manual-modal/video-manual-modal.module';
import { ListaPresencaEventoModule } from './components/lista-presenca-evento/lista-presenca-evento.module';
import { ReportarErroModule } from './components/reportar-erro/reportar-erro.module';
import { AvisoGeralModule } from './components/aviso-geral/aviso-geral.module';
//import { PesquisaSatisfacaoFeiraModule } from './components/pesquisa-satisfacao-feira/pesquisa-satisfacao-feira.module';


@NgModule({
  imports: [
    RouterModule,
    LoginModule,
    VisualizaAssinaturaModule,
    AberturaEditalModule,
    ListaPresencaEventoModule,
    ChangePasswordModule,
    ReportarErroModule,
    ForgotPasswordModule,
    AgropolosMaterialModule,
    EditalMulherLocalizacaoModule,
    VideoManualModalModule,
    AvisoGeralModule,
    //PesquisaSatisfacaoFeiraModule,
  ],
  declarations: [
    ShellComponent,
    SidebarComponent
  ]
})
export class ShellModule { }
