import { Component, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { validateAllFormFields } from '../../../constantes';
import { ShellService } from '../../../core/shell.service';
import { ReportarErroService } from './reportar-erro.service';
import { ReportarErroModel } from '../../../models/integra/reportarerromodel';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { take } from 'rxjs/operators';

@Component({
  selector: 'reportar-erro',
  templateUrl: './reportar-erro.component.html',
  styleUrls: ['./reportar-erro.component.scss']
})
export class ReportarErroComponent implements OnInit {

  public formGroup: FormGroup;
  public entity: ReportarErroModel;
  public mensagem: string;
  public exibirLoading: boolean;
  public color: string = "green";
  public caracteres: string = "[@$!%*?&#]";

  constructor(
    private formBuilder: FormBuilder,
    private ReportarErroService: ReportarErroService,
    private shellService: ShellService,
    public thisDialogRef: MatDialogRef<ReportarErroComponent>,
    private _ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public data: any) 
    { 

    }
    @ViewChild('autosize', { static: true }) autosize: CdkTextareaAutosize;

    triggerResize() {
      this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngOnInit() {
    console.log("ngOnInit--this.data",this.data)
    this.formGroup = this.formBuilder.group({
      Email: new FormControl(null, Validators.required),
      Observacao: new FormControl(null, Validators.required),
    });
  }

  private prepareToSave(formModel: ReportarErroModel): void {
    this.entity = { ...this.entity, ...formModel };
  }

  public save(formModel: ReportarErroModel, isValid: boolean): void {
    
    // if(formModel.NewPassword !== formModel.ConfirmPassword){
    //   this.formGroup.controls['ConfirmPassword'].setErrors({'incorrect': true});
    //   isValid = false;
    // }    

    if (isValid) {
      this.color = "green"
      this.mensagem = 'Processando...';
      this.exibirLoading = true;

      this.prepareToSave(formModel);
      //console.log("save--ReportarErroModel", this.entity)
      this.ReportarErroService.reportarErro(formModel).subscribe(data => {
        this.thisDialogRef.close();
        this.shellService.alert().success({ messages: ['Erro reportado com sucesso!'], timeout: 3000 });
      },
      error => {
        //console.log('error', error)
        this.color = "red"
        this.exibirLoading = true;
        this.shellService.alert().error({ messages: ['Dados inválidos, tente novamente!'], timeout: 3000 });
        //this.mensagem = 'Dados inválidos, verifique e tente novamente!';
      });

    } else {
      validateAllFormFields(this.formGroup);
      this.shellService.alert().error({ messages: ['Preencha todos os campos obrigatórios corretamente!'], timeout: 3000 });
    }
  }

  public handleError = (controlName: string, errorName: string) => {
    return this.formGroup.controls[controlName].hasError(errorName);
  } 

  back(): void {
    this.thisDialogRef.close();
  }

}
