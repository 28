import { NgModule } from '@angular/core';

import { ReportarErroComponent } from './reportar-erro.component';
import { AgropolosMaterialModule } from '../../agropolos-material.module';
import { ReportarErroService } from './reportar-erro.service';
import { ReportarErroRoutingModule } from './reportar-erro-routing.module';
import { MaterialModulos } from '../../../material-modulos.module';


@NgModule({
  imports: [AgropolosMaterialModule, ReportarErroRoutingModule, MaterialModulos],
  declarations: [ReportarErroComponent],
  exports: [ReportarErroComponent],
  entryComponents: [ReportarErroComponent],
  providers: [ReportarErroService]
})
export class ReportarErroModule { }
