import { Component, OnInit, OnDestroy } from '@angular/core';
import * as strings from '../../../../assets/strings';
import { HttpBaseService } from '../../../core/http-base.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, timer} from 'rxjs';
import { startWith, map, take } from "rxjs/operators";
import { Noticia } from '../../../models/integra/noticia';

export interface noticia {
  id: number;
  imagem: string;
  data: Date;
  ativo: string;
  descricao: string;
  detalhe: string;
  link: string;
}

@Component({
  selector: 'agropolos-footer',
  templateUrl: './agropolos-footer.component.html',
  styleUrls: ['./agropolos-footer.component.scss']
})
export class AgropolosFooterComponent implements OnInit, OnDestroy {
  public versaoSistema: any;
  public showFooter: boolean = true;
  public funcionalidade: string;
  public btnProximo: boolean = false;
  public btnAnterior: boolean = false;
  public noticiaList: [];
  public currentPicture: number = 0;
  public lengthListaNoticias: number = 0;
  public qtdSegundos: number=10;
  public showNoticias:boolean=false;

   //parametors pra timer de verificar noticia
  public countDown: Subscription;
  public counter = this.qtdSegundos; //60 segundos = 1 minuto
  public tick = 1000;
   ///

  str = {
    nomeApp: strings.nomeAPP,
    sigla: strings.sigla,
    versao: strings.versao,
    ano: strings.ano
  };

  // pictures = [
  //   'https://images.unsplash.com/photo-1551198297-e490636298ba?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  //   'https://images.unsplash.com/photo-1542742518-7ad6e6b91bc2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2090&q=80',
  //   'https://images.unsplash.com/photo-1456014673271-90b7fddf2eea?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80',
  //   'https://images.unsplash.com/photo-1551025119-77673c1d37cd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80'
  // ];


  // noticias = [
  //   {
  //     imagem: 'https://www.sda.ce.gov.br/wp-content/uploads/sites/60/2024/10/8a1b9eff-c94f-4296-b066-d3d2f2a2816e.jpg',
  //     link: 'https://www.sda.ce.gov.br/2024/10/10/banco-mundial-faz-avaliacao-positiva-do-projeto-sao-jose-iv/',
  //     descricao: 'Banco Mundial faz avaliação positiva do Projeto São José IV',
  //     detalhe: 'Na manhã desta quinta-feira (10), o gabinete da Secretaria do Desenvolvimento Agrário (SDA) sediou o encerramento da Missão de Supervisão do Banco Mundial ao Projeto São José (PSJ). ',
  //   },
  //   {
  //     imagem: 'https://www.sda.ce.gov.br/wp-content/uploads/sites/60/2024/10/WhatsApp-Image-2024-10-03-at-15.10.00-2048x1584.jpeg',
  //     link: 'https://www.sda.ce.gov.br/2024/09/28/jovens-da-delegacao-do-ceara-no-terra-madre-aprendem-sobre-cafe-sustentabilidade-e-troca-de-saberes/',
  //     descricao: 'Jovens da delegação do Ceará no Terra Madre aprendem sobre café, sustentabilidade e troca de saberes',
  //     detalhe: 'No terceiro dia do Terra Madre 2024, a delegação do Ceará, representada pela Secretaria do Desenvolvimento Agrário (SDA), teve uma experiência enriquecedora no universo do café. Durante a visita guiada ao Museu do Café Lavazza, em Turim, na Itália',
  //   },
  //   {
  //     imagem: 'https://www.sda.ce.gov.br/wp-content/uploads/sites/60/2024/10/WhatsApp-Image-2024-10-03-at-13.44.31-1-2048x1152.jpeg',
  //     link: 'https://www.sda.ce.gov.br/2024/09/27/a-biodiversidade-da-caatinga-na-agricultura-familiar-do-semiarido-cearense-ganhou-os-espacos-do-terra-madre/',
  //     descricao: 'A biodiversidade da caatinga na agricultura familiar do semiárido cearense ganhou os espaços de Terra Madre, em Turim, Itália.',
  //     detalhe: 'A juventude do Projeto São José e a biodiversidade da caatinga na agricultura familiar do semiárido foram destaques no espaço Farm do Terra Madre 2024, evento internacional que reúne agricultores familiares, indígenas e especialistas em sustentabilidade.',
  //   },
  //   {
  //     imagem: 'https://www.sda.ce.gov.br/wp-content/uploads/sites/60/2024/09/WhatsApp-Image-2024-09-20-at-08.38.24.jpeg',
  //     link: 'https://www.sda.ce.gov.br/2024/09/20/projeto-sao-jose-participa-de-congresso-internacional-de-nutricao-funcional/',
  //     descricao: 'Projeto São José/SDA participa de Congresso Internacional de Nutrição Funcional',
  //     detalhe: 'A Secretaria do Desenvolvimento Agrário do Ceará, por meio do Projeto São José, marcou presença no primeiro dia do Congresso Internacional de Nutrição Funcional, realizado em São Paulo. O evento, de grande relevância no setor, destaca-se como uma oportunidade única para conectar a agricultura familiar do estado a novas perspectivas de mercado e inovação.',
  //   },
  // ]

  constructor(
    private http: HttpBaseService,
    private route: ActivatedRoute,
  ) { }  

  ngOnDestroy() {
  }

  ngOnInit() {
    //console.log("this.noticias.length", this.noticias.length)
    //console.log("this.currentPicture", this.currentPicture)
    
    this.carregarNoticias();
    setTimeout(() => this.verificaBotoes(), 2000);
    setTimeout(() => this.rotinaVerificarNoticia(), 3000);
    //this.verificaBotoes();
    //this.rotinaVerificarNoticia();    

    this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0];
    if (this.funcionalidade == 'pesquisasatisfacaofeira') {
      this.showFooter = false;
    }
    else if (this.funcionalidade == 'fazendocontas') {
      this.showFooter = false;
    }
    else {
      this.showFooter = true;
    }

    // this.http.get("/Seplag/ExibeVersaoSistema", false).subscribe((retorno: any) => {
    //   ////console.log("ngOnInit----ExibeVersaoSistema",  Object(retorno)["versaoSistema"]);
    //   this.versaoSistema = Object(retorno)["versaoSistema"];

    //   if (this.funcionalidade == 'pesquisasatisfacaofeira') {
    //     this.showFooter = false;
    //   }
    //   else if (this.funcionalidade == 'fazendocontas') {
    //     this.showFooter = false;
    //   }
    //   else{
    //     this.showFooter = true;
    //   }
    // })
  };

  // select(index) {
  //   this.currentPicture = index;
  // }

  selectProximoArrow() {
    if (this.currentPicture < this.lengthListaNoticias) { this.currentPicture++; }
    this.verificaBotoes();
  }

  selectAnteriorArrow() {
    if (this.currentPicture > 0) { this.currentPicture--; }
    this.verificaBotoes();
  }

  verificaBotoes() {
    this.btnAnterior = this.currentPicture > 0;
    this.btnProximo = this.currentPicture < this.lengthListaNoticias;
  }

  rotinaVerificarNoticia() {    
    this.counter = this.qtdSegundos; //60 segundos = 1 minuto
    this.countDown = timer(0, this.tick).subscribe((count) => {
      //inicia a contagem
      --this.counter;
      //console.log('this.counter', this.counter);
      //verifica se chegou a zero a contagem
      if (this.counter == 0) {
        // console.log('timer completed--count', count);
        // console.log('timer completed--this.counter', this.counter);
        // console.log('this.currentPicture',this.currentPicture);
        // console.log('this.lengthListaNoticias',this.lengthListaNoticias);

        //enquanto nao chegar ao fim de noticias passa pro proximo
        if(this.currentPicture < this.lengthListaNoticias)
        {
          this.selectProximoArrow();
        }
        else
        {
          if(this.currentPicture === this.lengthListaNoticias)
          {
            this.currentPicture=0;
            //this.carregarNoticias();
            this.verificaBotoes();
          }
        }

        //executa novamente a contagem
        if(this.countDown)
        {
          this.pararRotina();
          this.rotinaVerificarNoticia();
        }
        
      }
      
    });
  }

  pararRotina(){
    this.countDown.unsubscribe();
    this.countDown = null;
  }

  public carregarNoticias()
  {
    this.http.post(`/Noticia/VerificaNoticias`, false).subscribe((data: any) => {
      this.http.get(`/Noticia/CarregarNoticia`, false).subscribe((data: []) => {
        //console.log("carregarNoticias--data", data);      
        this.noticiaList = data;
        this.lengthListaNoticias = (this.noticiaList.length-1);    
        this.showNoticias = this.lengthListaNoticias>0; 
        this.btnAnterior = this.currentPicture > 0;
        this.btnProximo = this.currentPicture < this.lengthListaNoticias;
      });
    });
    
  }

  // public VerificaNoticias() {
  //   this.http.post(`/Noticia/VerificaNoticias`, false).subscribe((data: any) => {
  //   });
  // }

}
