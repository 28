import { CredenciaisService } from '../../../../core/credenciais.service';
import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, Pipe } from '@angular/core';
import { AgropolosDialogService } from '../../../components/agropolos-dialog/agropolos-dialog.service';
import { ChangePasswordComponent} from '../../../components/change-password/change-password.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpBaseService } from '../../../../core/http-base.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ReportarErroComponent } from '../../../components/reportar-erro/reportar-erro.component';
import { NotificacaoSistema } from '../../../../models/integra/notificacao-sistema';
import * as moment from 'moment';

@Component({
  selector: 'agropolos-toolbar-user-profile',
  templateUrl: './agropolos-mat-toolbar-user-profile.component.html',
  styleUrls: ['./agropolos-mat-toolbar-user-profile.component.scss']
})
export class AgropolosMatToolbarUserProfileComponent implements OnInit {

  @Input() perfilUsuario: string;
  @Input() nomeUsuario: string;
  @Input() emailUsuario: string;
  @Input() cpfUsuario: string;
  @Input() secretaria: string;

  @Input() homeTooltip: string;
  @Input() reportBugLocation: string;
  @Input() homeLocation: string;

  @Output() logoff: EventEmitter<any> = new EventEmitter<any>();
  @Output() reportBug: EventEmitter<any> = new EventEmitter<any>();
  @Output() homeClick: EventEmitter<any> = new EventEmitter<any>();

  public funcionalidade: string;
  public showMenu:boolean=false; 
  public nomeCompletoUsuario:string;
  public usuarioLogado:any;

  public showMidia: boolean = false;
  public imagePath: any;
  public imagePathmidia: any;

  public showParabens:boolean=false;
  hoje: any = new Date(moment(Date.now()).format('MM-DD'));
  aniversario:any;

  badgeCount: number;

  public notificacaoList: NotificacaoSistema[]=[];

  constructor(
    private dialog: AgropolosDialogService,
    private credenciaisService: CredenciaisService,
    public matdialog: MatDialog,
    private view: ViewContainerRef,
    private route: ActivatedRoute,
    private http: HttpBaseService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    ) {
    this.dialog.viewContainerRef = this.view;
    //this.badgeCount = 5;
  }

  ngOnInit() {
    this.funcionalidade = this.route.snapshot['_routerState'].url.replace('/', '').split('/', 1)[0];
    this.usuarioLogado = JSON.parse(localStorage.getItem("currentUser"));
    //console.log("AgropolosMatToolbarUserProfile---usuarioLogado", this.usuarioLogado)
    this.buscarPessoaFisicaLogado();
    this.buscarFuncionarioLogado();
    this.carregarNotificacoes();
    this.carregarParabens();
   
    if(this.funcionalidade == 'editalmulheresruraiscadastro'){
      this.showMenu = false;
    } else if(this.funcionalidade == 'licitaben'){
      this.showMenu = false;
    }
    else if(this.funcionalidade == 'pesquisasatisfacaofeira'){
      this.showMenu = false;
    }
    else if(this.funcionalidade == 'fazendocontas'){
      this.showMenu = false;
    }
    else
    {this.showMenu = true;}
  }

  preencheInfos() {

  }

  onLogoff(event: any) {
    const config = {
      title: 'Sair do sistema.',
      content: 'Você deseja mesmo sair do sistema?',
      actions: [
        { name: 'NÃO', closeResult: false, color: 'accent' },
        { name: 'SIM', closeResult: true, color: 'primary' }
      ],
      isAlert: true,
      alertType: 'confirm'
    };

    this.dialog.open(config).afterClosed().subscribe(
      result => {
        this.logoff.emit(event);
        if (result) {
          //this.localStorageService.setObject('gridSettingsModel', null);
          this.credenciaisService.logout();

          if(JSON.parse(localStorage.getItem("currentUser")) != null)
          {localStorage.setObject("currentUser", null);}
        }
      }
    );
  }

  chancePassword(): void {
    const _dialog = this.matdialog.open(
      ChangePasswordComponent,
      {
        width: '600px',
        height: '420px',
        data: { 
          emailUsuario : this.emailUsuario,
          cpfUsuario:this.usuarioLogado.cpf
        }
      }
    );
    _dialog.afterClosed().subscribe(() => {
      
    });
  }

  onReportBug(event: any) {
    const _dialog = this.matdialog.open(
      ReportarErroComponent,
      {
        width: '600px',
        height: '420px',
        data: { usuarioLogado : this.usuarioLogado}
      }
    );
    _dialog.afterClosed().subscribe(() => {
      
    });
    // window.open(this.reportBugLocation, '_blank');
    // this.reportBug.emit(event);
  }

  onHomeClick(event: any) {
    this.homeClick.emit(event);
    window.open(this.homeLocation);
  }

  nomeAbreviado() {
    const nameArray: Array<string> = this.nomeUsuario.split(' ');
    const lastIndex: number = nameArray.length - 1;

    return nameArray[0] + ' ' + nameArray[lastIndex];
  }

  nome() {
    return this.nomeCompletoUsuario;//this.nomeUsuario
  }

  private buscarPessoaFisicaLogado() {    
    if(this.usuarioLogado != null)
    {      
      //this.http.get(`/PessoaFisica/BuscaPessoaFisicaLogado/${usuarioLogado.cpf}`, false).subscribe((data: any) => {
        this.http.get(`/PessoaFisica/BuscaPessoaFisicaLogadoHash/${this.usuarioLogado.hash}`, false).subscribe((data: any) => {
        if (data != null) {
          var pessoaFisicaLogado = Object(data)
          this.nomeCompletoUsuario = pessoaFisicaLogado.dscNomeCompleto;
          //console.log("buscarPessoaFisicaLogado", pessoaFisicaLogado)
        }
      });
    }
  }

  private buscarFuncionarioLogado() {    
    if(this.usuarioLogado != null)
    {      
        this.http.get(`/Funcionario/BuscaFuncionarioLogadoHash/${this.usuarioLogado.hash}`, false).subscribe((data: any) => {
        if (data != null) {
          //console.log("buscarFuncionarioLogado", data)
          var funcionarioLogado = Object(data)
          this.showMidia = funcionarioLogado.funcionario.fotoTecnico != null;
          this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl(funcionarioLogado.funcionario.fotoTecnico);
          this.imagePathmidia = funcionarioLogado.funcionario.fotoTecnico;
        }
      });
    }
  }

  public carregarNotificacoes()
  {
    if(this.usuarioLogado!=null)
    {
      this.http.get(`/NotificacaoSistema/CarregarNotificacaoSistema/${this.usuarioLogado.hash}`, false).subscribe((data: NotificacaoSistema[]) => {
        //console.log("carregarNotificacoes--data", data);
        //console.log("carregarNotificacoes--data.length", data.length);
        this.notificacaoList = data;
        this.badgeCount =  data.length;
      });
    }
    
  }

  public carregarParabens()
  {
    if(this.usuarioLogado!=null)
    {
      this.http.get(`/pessoafisica/CarregarParabens/${this.usuarioLogado.hash}`, false).subscribe((info: any) => {
        this.aniversario = new Date(moment(info.dthNascimento).format('MM-DD'));
        this.showParabens = this.hoje.getTime() == this.aniversario.getTime();
        //console.log("carregarParabens--hoje",this.hoje)
        //console.log("carregarParabens--aniversario", this.aniversario);
        //console.log("carregarParabens--showParabens", this.showParabens);
      });
    }
    
  }

  public onClickLido(item:any)
  {
    console.log("onClickLido--item", item);
    this.http.post(`/NotificacaoSistema/NotificacaoSistemaLida/${item.id}`, true).subscribe((result) => {
      //console.log("onClickLido---result", Object(result)["notificacao"]);
      var retorno = Object(result)["notificacao"];
      
      this.router.navigate(["/"+retorno.modulo+""]);
      
      // let currentUrl = retorno.modulo;//this.router.url;
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      // this.router.navigate([currentUrl]);
      //});

      this.carregarNotificacoes();
    });
  }

}
