import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ReportarErroModel } from '../../../models/integra/reportarerromodel';

@Injectable({
  providedIn: 'root'
})
export class ReportarErroService {

  private apiUrl = '';

  constructor(private http: HttpClient) { 
    this.apiUrl = environment.backendUrl;
  }

  // forgotpassword(email: string) {
  //   return this.http.get<any>(`${this.apiUrl}/manage/forgotpassword?email=` + email);
  // }

  reportarErro(model: ReportarErroModel) {
    return this.http.post<any>(`${this.apiUrl}/ServicoAutomatizado/reportarerro`, model);
    //return this.http.post<any>(`${this.apiUrl}/manage/ReportarErro`, model);
  }

}
