import { Time } from "@angular/common";
import { BaseModel } from "../base-model";
import { Dominio } from "./Dominio";
import { AgendamentoEvento } from "./agendamento-evento";
import { PessoaFisica } from "./pessoa-fisica";

export class AgendamentoEventoListaPresenca extends BaseModel {

  constructor(
      public id: number,
      public cpf: string,
      public nome: string,
      public email: string,
      public instituicao: string,
      public telefone: string,
      public assinatura: string,
      public dataAssinatura: Date,
      public dataEnvioEmail: Date,
      public envioEmail: boolean,
      
      public agendamentoEventoId: number,
      public agendamentoEvento: AgendamentoEvento,

  ) { super(); }

}
