import { AvisoGeralRoutingModule } from './aviso-geral-routing.module';
import { AvisoGeralService } from './aviso-geral.service';
import { NgModule } from '@angular/core';
import { AvisoGeralComponent } from './aviso-geral.component';
import { MaterialModulos } from '../../../material-modulos.module';
import { AgropolosMaterialModule } from '../../agropolos-material.module';

@NgModule({
  imports: [AgropolosMaterialModule, AvisoGeralRoutingModule, MaterialModulos],
  declarations: [AvisoGeralComponent],
  exports: [AvisoGeralComponent],
  providers: [AvisoGeralService]
})
export class AvisoGeralModule { }
