import { AvisoGeralComponent } from './aviso-geral.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [ { path: 'avisogeral', component: AvisoGeralComponent } ];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AvisoGeralRoutingModule { }
