import { BaseModel } from "../base-model";
import { PessoaJuridica } from "./pessoa-juridica";
import { Contato } from "./contato";
import { Funcionario } from "./funcionario";
import { FuncionarioMembro } from "./funcionario-membro";
import { Dependente } from "./dependente";
import { Endereco } from "./endereco";
import { Dominio } from "./Dominio";
import { PessoaFisica } from "./pessoa-fisica";

export class MiMulherRural extends BaseModel {

  constructor(
    public id: number,
    public dscNomeCompleto: string,
    public codCpf: string,
    public dthNascimento: Date,
    public email: string,
    public contatoCelular: string,
    public contatoFixo: string,
    public dscNomeMae: string,
    public dscNomePai: string,
    public dscNomeSocial: string,
    public flgEstadoCivil: string,
    public flgTemDeficiencia: string,
    public flgChefeFamilia: string,
    public codRg: string,
    public dthEmissaoRg: Date,
    public dscOrgaoRg: string,
    public dscNis: string,
    public dscDap: string,
    public dscOutraTipologia: string,
    public flgMeioRural: boolean,
    public dscEnderecoResidencial: string,
    public numEnderecoResidencial: string,
    public cepEnderecoResidencial: string,
    public dscBairroEnderecoResidencial: string,
    public numLatitudeResidencial: number,
    public numLongitudeResidencial: number,
    public territorio: string,
    public comunidade: string,
    public atividadeRealiza: string,
    public iniciativa: string,
    public numTempoNegocio: number,
    public vlrPropostaNegocio: number,
    public flagGeraRenda: boolean,
    public numQtdPessoas: number,
    public vlrMensal: number,
    public vlrReceitaBruta: number,
    public vlrDespesaTotal: number,
    public vlrLucro: number,
    public vlrMargemLucro: number,
    public flagInformacaoPessoal: boolean,
    public flagPropostaNegocio: boolean,
    public flagPortifolio: boolean,
    public flagTermoCompromisso: boolean,
    public flagTermoAnexo2: boolean,
    public flagTermoAnexo3: boolean,
    public flagTermoAnexo6: boolean,
    public flagTermoAnexo8: boolean,
    public flagTermoAnexo11: boolean,

    public fraseNegocio: string,
    public linkVideoNegocio: string,

    public cepEnderecoNegocio: string,
    public numEnderecoNegocio: string,
    public dscEnderecoNegocio: string,
    public dscBairroEnderecoNegocio: string,
    public numLatitudeNegocio: number,
    public numLongitudeNegocio: number,
    public territorioNegocio: string,
    public comunidadeNegocio: string,
    public contatoNegocioCelular: string,
    public contatoNegocioFixo: string,
    public pontoReferencia: string,

    public numClassificacao: number,
    public flagDesclassificacao: boolean,
    public flagMesmoEndereco: boolean,
    public dataDesclassificacao: Date,
    public dataEnvioManifestacao: Date,
    public dataAberturaManifestacao: Date,

    public situacaoFinal: string,
    public justificativaAnalise: string,

    public situacaoPosSelecao: string,

    public ipPreCadastro: string,
    public ipCadastro: string,
    public ipPesquisa: string,

    public identidadeGeneroId: number,
    public identidadeGenero: Dominio,

    public tipoDeficienciaId: number,
    public tipoDeficiencia: Dominio,

    public tipologiaPctId: number,
    public tipologiaPct: Dominio,

    public municipioId: number,
    public municipio: Dominio,

    public distritoId: number,
    public distrito: Dominio,

    public municipioNegocioId: number,
    public municipioNegocio: Dominio,

    public distritoNegocioId: number,
    public distritoNegocio: Dominio,

    public tecnicoAnalisadorId: number,
    public tecnicoAnalisador: PessoaFisica,

    // public terrritorioId: number,
    // public terrritorio: Dominio,    

    public flgParecerFinal: string,

  ) { super(); }

}

