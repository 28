import { RouterModule } from '@angular/router';
import { AgropolosHttpStatusComponent } from './components/agropolos-http-status/agropolos-http-status.component';
import { SharedModule } from '../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';

import { ContadorSessaoComponent } from './components/contador-sessao/contador-sessao.component';

import { AgropolosMatSidenavComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav/agropolos-mat-sidenav.component';
import { AgropolosMatSidenavContainerComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-container/agropolos-mat-sidenav-container.component';
import { AgropolosMatToolbarComponent } from './layout/agropolos-toolbar/agropolos-mat-toolbar/agropolos-mat-toolbar.component';
import { AgropolosMatSidenavSectionComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-section/agropolos-mat-sidenav-section.component';
import { AgropolosMatSidenavContentFooterComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-content-footer/agropolos-mat-sidenav-content-footer.component';
import { AgropolosMatSidenavContentComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-content/agropolos-mat-sidenav-content.component';
import { AgropolosMatSidenavExpansionMenuComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-expansion-menu/agropolos-mat-sidenav-expansion-menu.component';
import { AgropolosMatSidenavSelectionListComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-selection-list/agropolos-mat-sidenav-selection-list.component';
import { AgropolosMatToolbarThemesComponent } from './layout/agropolos-toolbar/agropolos-mat-toolbar-themes/agropolos-mat-toolbar-themes.component';
import { AgropolosMatToolbarUserProfileComponent } from './layout/agropolos-toolbar/agropolos-mat-toolbar-user-profile/agropolos-mat-toolbar-user-profile.component';
import { AgropolosMatSidenavItemComponent } from './layout/agropolos-sidenav/agropolos-mat-sidenav-item/agropolos-mat-sidenav-item.component';

import { AgropolosMatToolbarService } from './layout/agropolos-toolbar/agropolos-mat-toolbar/agropolos-mat-toolbar.service';
import { AgropolosMatSidenavContainerService } from './layout/agropolos-sidenav/agropolos-mat-sidenav-container/agropolos-mat-sidenav-container.service';
import { AgropolosMatSidenavService } from './layout/agropolos-sidenav/agropolos-mat-sidenav/agropolos-mat-sidenav.service';


import { AgropolosMatProgressbarComponent } from './components/agropolos-progressbar/agropolos-mat-progressbar.component';
import { AgropolosMatContainerComponent } from './components/agropolos-container/agropolos-mat-container.component';
import { AgropolosDialogComponent } from './components/agropolos-dialog/agropolos-dialog.component';
import { AgropolosAlertComponent } from './components/agropolos-alert/agropolos-alert.component';

import { AgropolosDialogService } from './components/agropolos-dialog/agropolos-dialog.service';
import { AgropolosMatProgressbarService } from './components/agropolos-progressbar/agropolos-mat-progressbar.service';
import { AgropolosMatContainerService } from './components/agropolos-container/agropolos-mat-container.service';
import { AgropolosMatSidenavContentService } from './layout/agropolos-sidenav/agropolos-mat-sidenav-content/agropolos-mat-sidenav-content.service';
import { AgropolosAlertService } from './components/agropolos-alert/agropolos-alert.service';
import { AgropolosFooterComponent } from './layout/agropolos-footer/agropolos-footer.component';
import { VisualizarDocumentoComponent } from './components/visualizar-documento/visualizar-documento.component';
import { LoaderService } from '../shared/interceptors/loader.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from '../shared/interceptors/loader-interceptor.service';
import { MyLoaderComponent } from './components/interceptors/my-loader/my-loader.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ContadorSessaoComponent,
    AgropolosMatToolbarComponent,
    AgropolosMatSidenavContainerComponent,
    AgropolosMatSidenavComponent,
    AgropolosMatSidenavContentComponent,
    AgropolosMatSidenavContentFooterComponent,
    AgropolosMatSidenavSectionComponent,
    AgropolosMatSidenavItemComponent,
    AgropolosMatSidenavSelectionListComponent,
    AgropolosMatSidenavExpansionMenuComponent,
    AgropolosMatToolbarUserProfileComponent,
    AgropolosMatToolbarThemesComponent,
    AgropolosMatContainerComponent,
    AgropolosMatProgressbarComponent,
    AgropolosDialogComponent,
    AgropolosAlertComponent,
    AgropolosHttpStatusComponent,
    AgropolosFooterComponent,
    VisualizarDocumentoComponent,
    MyLoaderComponent
    
  ],
  exports: [
    SharedModule,
    AgropolosMatToolbarComponent,
    AgropolosMatSidenavContainerComponent,
    AgropolosMatSidenavComponent,
    AgropolosMatSidenavContentComponent,
    AgropolosMatSidenavContentFooterComponent,
    AgropolosMatSidenavSectionComponent,
    AgropolosMatSidenavItemComponent,
    AgropolosMatSidenavSelectionListComponent,
    AgropolosMatSidenavExpansionMenuComponent,
    AgropolosMatToolbarUserProfileComponent,
    AgropolosMatToolbarThemesComponent,
    AgropolosMatContainerComponent,
    AgropolosMatProgressbarComponent,
    AgropolosDialogComponent,
    AgropolosAlertComponent,
    AgropolosHttpStatusComponent,
    AgropolosFooterComponent,
    VisualizarDocumentoComponent,
    MyLoaderComponent,
    // DocumentoModalComponent,
    // MetaDataTagModalComponent,
    // HistoricoAssinaturasComponent,
    // AssinaturaComponent,
    // PublicarEdoWebComponent,
    // NegarAssinaturaComponent,
    // DefinirDataPublicacaoComponent
  ],
  entryComponents: [
    AgropolosDialogComponent,
    VisualizarDocumentoComponent,
    MyLoaderComponent
    // DocumentoModalComponent,
    // MetaDataTagModalComponent,
    // HistoricoAssinaturasComponent,
    // AssinaturaComponent,
    // PublicarEdoWebComponent,
    // NegarAssinaturaComponent,
    // DefinirDataPublicacaoComponent
  ],
  providers: [
    AgropolosMatToolbarService,
    AgropolosMatSidenavService,
    AgropolosMatContainerService,
    AgropolosMatSidenavContainerService,
    AgropolosMatSidenavContentService,
    AgropolosMatProgressbarService,
    AgropolosDialogService,
    AgropolosAlertService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ]
})
export class AgropolosMaterialModule { }
