import { AfterViewInit, Component, ElementRef, HostListener, Input, NgZone, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from '../../../../environments/environment';
import { HttpBaseService } from '../../../core/http-base.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ShellService } from '../../../core/shell.service';
import { DatePipe } from '@angular/common';
import { EditalMulher } from '../../../models/integra/edital-mulher';
import { cpf } from 'cpf-cnpj-validator';
import { ListaPresencaEventoService } from './lista-presenca-evento.service';
import { setDatepicker, validarAnobrowser, validateAllFormFields } from '../../../constantes';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Dominio } from '../../../models/integra/Dominio';
import { Observable, throwError } from 'rxjs';
import { catchError, map, startWith, tap } from "rxjs/operators";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { NavegacaoService } from '../../../core/navegacao.service';
import { ListaPresencaEvento } from '../../../models/integra/lista-presenca-evento';
import { ListaPresencaEmailDialogComponent } from './assinatura-dialog/assinatura-dialog.component';

@Component({
  templateUrl: './lista-presenca-evento.component.html',
  styleUrls: ['./lista-presenca-evento.component.scss'],
  providers: [DatePipe]
})

export class ListaPresencaEventoComponent implements OnInit {

  @Input() name: string;
  @ViewChild('sigPad',  { static: false }) public sigPad: ElementRef;
  @ViewChild('canvas',  { static: true }) public canvas: ElementRef;

  sigPadElement;
  context;
  isDrawing = false;
  img;
    
  private emailValidators = [
    Validators.maxLength(250),
    Validators.minLength(5),
    Validators.pattern(/.+@.+\..+/)
  ];

  public funcionarioLogado: string;
  public formGroup: FormGroup;
  public urlftp;
  public entity: ListaPresencaEvento;
  public chaveTabelaId: number;

  public evento: string;
  public periodo: any;
  public horaI: any;
  public horaT: any;
  public local: string;
  public mensagemErro: string;
  public mensagemSucesso: string;
  public mensagemAviso: string;
  public showErro: boolean = false;
  public showSucesso: boolean = false;
  public showAviso: boolean = false;
  public cpfRegistrado: boolean = false;

  public condition: boolean = false;
  dataLoaded = true;
  public showCampos: boolean = true;
  public ipAddress: any;
  public tipo: string;
  public id: number;

  constructor(
    private http: HttpBaseService,
    private httpClient: HttpClient,
    private navegacaoService: NavegacaoService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private shellService: ShellService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _serviceListaPresenca: ListaPresencaEventoService,
    private _ngZone: NgZone,
    private datePipe: DatePipe,
    private _sanitizer: DomSanitizer,
    //private credenciaisService: CredenciaisService

  ) {
    //console.log("this.route.snapshot.params.id",this.route.snapshot.params.id)
    //
    let index = this.route.snapshot.params.id.indexOf('-');
    let _tipo = this.route.snapshot.params.id.substring(0,index)
    let _id = this.route.snapshot.params.id.substring(index+1)
    
    //console.log("index",index)
    //console.log("substring--tipo", _tipo)
    //console.log("substring--id", _id)

    this.tipo = _tipo;//this.route.snapshot.params.tipo;
    this.id = _id;//this.route.snapshot.params.id;
    this.initEntity();
    this.createFormValidators();
  }
  

  get f() { return this.formGroup.controls; }
  
  @HostListener('document:mouseup', ['$event'])
  onMouseUp(e) {
    this.isDrawing = false;
  }

  onMouseDown(e) {
    this.isDrawing = true;
    const coords = this.relativeCoords(e);
    this.context.moveTo(coords.x, coords.y);
  }

  onMouseMove(e) {
    if (this.isDrawing) {
      const coords = this.relativeCoords(e);
      this.context.lineTo(coords.x, coords.y);
      this.context.stroke();
    }
  }

  private relativeCoords(event) {
    const bounds = event.target.getBoundingClientRect();
    const x = event.clientX - bounds.left;
    const y = event.clientY - bounds.top;
    return { x: x, y: y };
  }

  clearsign() {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
  }

  savesign() {
    this.img = this.sigPadElement.toDataURL("image/png");
    console.log(this.img);
  }

  ngAfterViewInit() {    
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#3742fa';
  }  

  ngOnInit(): void {
    //console.log("ngOnInit",this.id)
    this.chaveTabelaId = this.id;
    this.formGroup.controls["chaveTabelaId"].setValue(this.id);

    this.carregaInfoEvento(this.chaveTabelaId, this.tipo);
  }

  public carregaInfoEvento(chaveTabelaId: number, tipo:string): void {
    //console.log("carregaInfoEvento--chaveTabelaId", chaveTabelaId)   
    console.log("carregaInfoEvento--tipo", tipo)   

    if(tipo=="agenda")
    {
      this.http.get("/AgendamentoEvento/CarregaInfoAgendamento/" + chaveTabelaId, false).subscribe(info => {
        //console.log("carregaInfoEvento", info)   
        this.evento = info.titulo;
        this.local = "UGP/PSJ - " + info.local.nome;
        this.periodo = moment(info.dtInicio).format('DD/MM/YYYY').toString();
        this.horaI = new Date(info.horaInicio);
        this.horaT = new Date(info.horaTermino);
        // console.log("carregaInfoEvento", this.hora.getHours())  
        // let times = info.data.split(':');
        // info.hora.setHours(parseInt(times[0]));
        // info.hora.setMinutes(parseInt(times[1]));  
      });
    }    

    if(tipo=="capacitacao")
      {
        this.http.get("/CapacitacaoEvento/CarregaInfoAgendamento/" + chaveTabelaId, false).subscribe(info => {
          //console.log("carregaInfoEvento", info)   
          this.evento = info.descricaoEvento;
          this.local = info.municipio.nome;
          this.periodo = moment(info.dtInicioEvento).format('DD/MM/YYYY').toString() + " a " +moment(info.dtTerminoEvento).format('DD/MM/YYYY').toString();
          this.horaI = new Date(info.horaInicio);
          this.horaT = new Date(info.horaTermino);
          // console.log("carregaInfoEvento", this.hora.getHours())  
          // let times = info.data.split(':');
          // info.hora.setHours(parseInt(times[0]));
          // info.hora.setMinutes(parseInt(times[1]));  
        });
      }    
  }

  ngOnDestroy(): void { }

  private initEntity(): ListaPresencaEvento {
    return new ListaPresencaEvento(null, null, null, null, null, null, null, null, null, null, null, null);
  }

  private createFormValidators(): void {
    if (this.entity === undefined) this.entity = new ListaPresencaEvento(0, null, null, null, null, null, null, null, null, null, null, null);
    this.formGroup = this.formBuilder.group({
      id: new FormControl({ value: this.entity.id, disabled: true }),
      cpf: new FormControl({ value: this.entity.cpf, disabled: false }),
      nome: new FormControl({ value: this.entity.nome, disabled: false }),
      telefone: new FormControl({ value: this.entity.telefone, disabled: false }),
      email: new FormControl({ value: this.entity.email, disabled: false }, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      assinatura: new FormControl({ value: this.entity.assinatura, disabled: false }),
      dataAssinatura: new FormControl({ value: this.entity.dataAssinatura, disabled: false }),
      instituicao: new FormControl({ value: this.entity.instituicao, disabled: false }),
      dataEnvioEmail: new FormControl({ value: this.entity.dataEnvioEmail, disabled: false }),
      envioEmail: new FormControl({ value: this.entity.envioEmail, disabled: false }),
      chaveTabelaId: new FormControl({ value: this.entity.chaveTabelaId, disabled: false }),
    });
  }

  private prepareToSave(formModel: ListaPresencaEvento): void {
    this.entity = { ...this.entity, ...formModel };
  }

  public save(formModel: ListaPresencaEvento, isValid: boolean): void {
    let assBranco = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZAAAACWCAYAAADwkd5lAAAAAXNSR0IArs4c6QAABVdJREFUeF7t1bENAAAIwzD6/9P8kNnsXSyk7BwBAgQIEAgCCxsTAgQIECBwAuIJCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQICAgPgBAgQIEEgCApLYjAgQIEBAQPwAAQIECCQBAUlsRgQIECAgIH6AAAECBJKAgCQ2IwIECBAQED9AgAABAklAQBKbEQECBAgIiB8gQIAAgSQgIInNiAABAgQExA8QIECAQBIQkMRmRIAAAQIC4gcIECBAIAkISGIzIkCAAAEB8QMECBAgkAQEJLEZESBAgICA+AECBAgQSAICktiMCBAgQEBA/AABAgQIJAEBSWxGBAgQICAgfoAAAQIEkoCAJDYjAgQIEBAQP0CAAAECSUBAEpsRAQIECAiIHyBAgACBJCAgic2IAAECBATEDxAgQIBAEhCQxGZEgAABAgLiBwgQIEAgCQhIYjMiQIAAAQHxAwQIECCQBAQksRkRIECAgID4AQIECBBIAgKS2IwIECBAQED8AAECBAgkAQFJbEYECBAgICB+gAABAgSSgIAkNiMCBAgQEBA/QIAAAQJJQEASmxEBAgQICIgfIECAAIEkICCJzYgAAQIEBMQPECBAgEASEJDEZkSAAAECAuIHCBAgQCAJCEhiMyJAgAABAfEDBAgQIJAEBCSxGREgQIDAA2P7AJdd5ThfAAAAAElFTkSuQmCC";
    //let assinatura = this.sigPadElement.toDataURL("image/png");//this._canvas.toDataURL();
    //console.log('save---base64', assinatura );

    let erro = 0;
    let _cpf = this.formGroup.controls["cpf"].value;// || this.formGroup.controls["cpf"].value.toString().trim() == ""; 
    let _nome = this.formGroup.controls["nome"].value;// || this.formGroup.controls["nome"].value.toString().trim() == ""; 
    let _instituicao = this.formGroup.controls["instituicao"].value;// || this.formGroup.controls["instituicao"].value.toString().trim() == ""; 
    let _email = this.formGroup.controls["email"].value;// || this.formGroup.controls["email"].value.toString().trim() == ""; 
    let _telefone = this.formGroup.controls["telefone"].value;// || this.formGroup.controls["telefone"].value.toString().trim() == ""; 

    if (_cpf == null || _cpf == "") {
      erro += 1;
    }

    if (_nome == null || _nome == "") {
      erro += 1;
    }

    if (_instituicao == null || _instituicao == "") {
      erro += 1;
    }

    if (_email == null || _email == "") {
      erro += 1;
    }

    if (_telefone == null || _telefone == "") {
      erro += 1;
    }

    // if (assinatura == assBranco) {
    //   erro += 1;
    // }

    //console.log('save---formModel', formModel );    

    if (erro == 0) {
      let erroCpf = 0;
      var cpfNumber = this.formGroup.controls["cpf"].value;
      if (cpfNumber.length == 11) {
        var cpfValid = cpf.isValid(cpfNumber);
        if (!cpfValid) {
          this.exibeMensagem("CPF não é válido!", "erro");
        }
        else {
          this.prepareToSave(formModel);

          this.entity.id = 0;
          this.entity.chaveTabelaId = Number(this.chaveTabelaId);
          this.entity.tipoEvento = this.tipo;
          //this.entity.assinatura = assinatura;
          //console.log("save---isValid---entity", this.entity)

          this._serviceListaPresenca.save(this.entity).subscribe(retorno => {
            this.exibeMensagem("Obrigado por confirmar sua presença.", "sucessofinal");
            this.showCampos = false;
            this.EnviarEmailAcesso(retorno.tipoEvento, retorno.chaveTabelaId);
          });
        }
      }

    }
    else {
      this.exibeMensagem("Preencha todos os campos marcados com *, eles são obrigatórios!", "erro");
      //this.shellService.alert().error({ messages: ['Preencha todos os campos obrigatórios corretamente!'], timeout: 3000 });
      validateAllFormFields(this.formGroup);
    }
  }

  public EnviarEmailAcesso(tipo:string, chaveTabelaId: number): void {
    this.http.get(`/ListaPresencaEvento/EnviarEmailPresencaEvento/${tipo}/${chaveTabelaId}`, false).subscribe((enviou) => {
      if (enviou.envio) {

        //this.exibeMensagem("Email enviado com sucesso", "sucesso");
        //this.shellService.alert().success({ messages: [enviou.msg], timeout: 3000 }); 
      }
      //else { this.shellService.alert().warning({ messages: [enviou.msg], timeout: 5000 }); }
    });
  }

  public carregar(): void { }

  public VerificaCpf(evt: any) {
    this.formGroup.controls["nome"].setValue(null);
    this.formGroup.controls["instituicao"].setValue(null);
    this.formGroup.controls["email"].setValue(null);
    this.formGroup.controls["telefone"].setValue("");

    var cpfNumber = this.formGroup.controls["cpf"].value;
    if (cpfNumber.length == 11) {
      var cpfValid = cpf.isValid(cpfNumber);
      if (!cpfValid) {
        this.exibeMensagem("CPF não é válido!", "erro");
      }
      else {
        this.http.get(`/ListaPresencaEvento/BuscarCpfPorEvento/${this.tipo}/${this.chaveTabelaId}/${cpfNumber}`, false).subscribe(info1 => {
          //console.log("BuscarCpfPorEvento", info1);
          if (info1.length > 0) {
            this.showCampos = false;
            this.exibeMensagem("Obrigado! Sua confirmação já foi realizada!", "sucesso");
          }
          else 
          {
            this.http.get(`/ListaPresencaEvento/BuscarBaseCpfEvento/${cpfNumber}`, false).subscribe(info2 => {
              //console.log("BuscarBaseCpfEvento", info2[0]);
              
              if (info2.length > 0) {
                this.exibeMensagem("Que bom que temos você novamente aqui.", "aviso");
                this.formGroup.controls["cpf"].setValue(info2[0].cpf);
                this.formGroup.controls["nome"].setValue(info2[0].nome);
                this.formGroup.controls["instituicao"].setValue(info2[0].instituicao);
                this.formGroup.controls["email"].setValue(info2[0].email);
                let telefone = info2[0].telefone != null ? info2[0].telefone.replace("(","").replace(")","").replace("-","").replace(" ","") : "";
                this.formGroup.controls["telefone"].setValue(telefone);
              }
              else 
              { 
                this.exibeMensagem("Preencha todos os campos e confirme.", "aviso"); 
                this.formGroup.controls["telefone"].setValue(null);
              }
            });
          }
        });
      }
    }
  }

  somenteNumeros(e: any) {
    let charCode = e.charCode ? e.charCode : e.keyCode;
    if (charCode != 8 && charCode != 9) {
      let max = 15;
      if ((charCode < 48 || charCode > 57) || (e.target.value.length >= max)) return false;
    }
  }
  reload() {
    window.location.reload();
  }

  changeUpperCase(textToUpper: string) {
    var newWord = textToUpper.toUpperCase();
  }

  exibeMensagem(mensagem: string, tipo: string) {
    this.showErro = false;
    this.showSucesso = false;
    this.showAviso = false;
    if (tipo == "erro") {
      this.mensagemErro = mensagem;
      this.showErro = true;
      setTimeout(() => this.showErro = false, 8000);
    }

    if (tipo == "sucesso") {
      this.mensagemSucesso = mensagem;
      this.showSucesso = true;
      setTimeout(() => this.showSucesso = false, 8000);
    }

    if (tipo == "aviso") {
      this.mensagemAviso = mensagem;
      this.showAviso = true;
      setTimeout(() => this.showAviso = false, 8000);
    }

    if (tipo == "sucessofinal") {
      this.mensagemSucesso = mensagem;
      this.showSucesso = true;
      //setTimeout(() => this.showSucesso = false, 5000); 
    }
  }

  public Assinar(): void {
    const _dialog = this.dialog.open(ListaPresencaEmailDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: 'auto',
      maxWidth: 'none',
      //height: '210px',
      data: {
        dataKey: this.chaveTabelaId
      }
    });
    _dialog.afterClosed().subscribe(r => {
      //console.log("Assinar----", r)
    });
  }


}
